import { metadataConfig } from "./sites/metadata.ts";
import { techVenturesConfig } from "./sites/techventures.ts";
import { dataAssetConfig } from "./sites/dataasset.ts";

// Default navigation configuration
const DEFAULT_NAV_CONFIG = {
  portfolioLabel: "Portfolio",
  showTechnology: true,
  showSolutions: true,

  showBlog: true,
  showNews: true,
  showHelp: true,

  showAuth: true,
};

export interface SiteConfig {
  company: {
    name: string;
    tagline: string;
    description: string;
  };
  theme: {
    primary: string;
    secondary: string;
    accent: string;
    background: string;
    text: string;
  };
  navigation?: {
    portfolioLabel?: string;
    showTechnology?: boolean;
    showSolutions?: boolean;
  
    showBlog?: boolean;
    showNews?: boolean;
    showHelp?: boolean;
  
    showAuth?: boolean;
  };
  footerLinks: {
    label: string;
    href: string;
  }[];
  aboutPage: {
    title: string;
    subtitle: string;
    mission: {
      title: string;
      content: string;
    };
    team: {
      name: string;
      role: string;
      bio: string;
    }[];
    values: {
      title: string;
      description: string;
    }[];
  };
  portfolioPage: {
    title: string;
    subtitle: string;
    companies: {
      name: string;
      description: string;
      status: string;
      year: number;
    }[];
    investmentCriteria: {
      title: string;
      description: string;
    }[];
  };
  homePage?: {
    hero?: {
      title?: string;
      highlight?: string;
      description?: string;
    };
    config?: {
      showPromoVideo?: boolean;
      showContactForm?: boolean;
    };
  };
  media?: {
    promoVideo?: string;
    promoVideoPoster?: string;
  };
}

let currentSite: string | undefined;

export function initializeSite(siteName: string) {
  currentSite = siteName;
}

export function getSiteConfig(overrideSite?: string): SiteConfig {
  // First try to use the override if provided
  let site = overrideSite || currentSite;
  
  // Then try to get from window if available
  if (!site && typeof window !== 'undefined') {
    site = window.SITE_NAME;
  }

  if (!site) {
    throw new Error("Site not initialized. Call initializeSite first or ensure window.SITE_NAME is set.");
  }

  console.log(`Getting config for site: ${site}`);

  let config: SiteConfig;
  switch (site) {
    case 'techventures':
      config = techVenturesConfig;
      break;
    case 'dataasset':
      config = dataAssetConfig;
      break;
    case 'metadata':
      config = metadataConfig;
      break;
    default:
      throw new Error(`Unknown site: ${site}`);
  }

  // Merge navigation config with defaults
  config.navigation = {
    ...DEFAULT_NAV_CONFIG,
    ...config.navigation
  };

  return config;
}
