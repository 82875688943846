import type { SiteConfig } from "../sites.ts";

const desc = `
MetaData Asset Management is a Toronto-based Venture Studio focussing on the emerging era of Agent-based AI.  We offer business and operational support, technology and innovation mentorship, and capital formation advisory services to our portfolio companies, leveraging our network's international reach to top AI resources and 24/7/365 operatives around the world.
`;

export const metadataConfig: SiteConfig = {
  company: {
    name: "MetaData Asset Management",
    tagline: "Transforming Data into Value",
    description: "Leading provider of data-driven asset management solutions"
  },
  theme: {
    primary: '#2563eb',    // Blue
    secondary: '#1d4ed8',
    accent: '#3b82f6',
    background: '#f9fafb',
    text: '#111827',
  },
  footerLinks: [
    { label: "About", href: "/about" },
    { label: "Privacy", href: "/privacy" },
    { label: "Terms", href: "/terms" },
    { label: "Contact", href: "#contact" }
  ],
  aboutPage: {
    title: "About MetaData Asset Management",
    subtitle: "The World's First AI Agent focussed Venture Studio",
    mission: {
      title: "Our Mission",
      content: "Building companies for the Era of Generative AI"
    },
    team: [
      {
        name: "Abdul Razzak Syed",
        role: "CEO",
        bio: "Chartered Accountant"
      },
      {
        name: "Wallace Trenholm",
        role: "CTO",
        bio: "25+ years mission critical systems design, 10+ years AI software architect"
      },
      {
        name: "Craig Trenholm",
        role: "COO",
        bio: "12+ years Project management and administration for Startup and Scale up companies"
      }
    ],
    values: [
      {
        title: "Innovation",
        description: "Constantly pushing the boundaries of what's possible in asset management."
      },
      {
        title: "Integrity",
        description: "Maintaining the highest standards of professional conduct and ethics."
      },
      {
        title: "Excellence",
        description: "Striving for exceptional results in everything we do."
      }
    ]
  },
  portfolioPage: {
    title: "Our Portfolio",
    subtitle: "Investing in Tomorrow's Technologies",
    companies: [
      {
        name: "DataTech Solutions",
        description: "AI-powered data analytics platform",
        status: "Series B",
        year: 2019
      },
      {
        name: "CloudScale",
        description: "Enterprise cloud infrastructure",
        status: "Series A",
        year: 2020
      },
      {
        name: "FinFlow",
        description: "Financial process automation",
        status: "Seed",
        year: 2021
      }
    ],
    investmentCriteria: [
      {
        title: "Technology Focus",
        description: "We invest in companies leveraging cutting-edge technology"
      },
      {
        title: "Market Potential",
        description: "Looking for solutions addressing large market opportunities"
      },
      {
        title: "Team Excellence",
        description: "Strong founding teams with domain expertise"
      }
    ]
  },
  homePage: {
    hero: {
      title: "Building companies for the Era of Generative AI",
      highlight: "a world driven by AI Agents is coming",
      description: "we help your company prepare for the disruption"
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  },
  media: {
    promoVideo: "/videos/intro.mp4",
    promoVideoPoster: "/images/video-poster.jpg"
  }
};
